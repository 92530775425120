import './App.css';
// import {
//   EmailShareButton,
//   FacebookShareButton,
//   GabShareButton,
//   HatenaShareButton,
//   InstapaperShareButton,
//   LineShareButton,
//   LinkedinShareButton,
//   LivejournalShareButton,
//   MailruShareButton,
//   OKShareButton,
//   PinterestShareButton,
//   PocketShareButton,
//   RedditShareButton,
//   TelegramShareButton,
//   TumblrShareButton,
//   TwitterShareButton,
//   ViberShareButton,
//   VKShareButton,
//   WhatsappShareButton,
//   WorkplaceShareButton,
// } from "react-share";

function App() { 
  const changeTitleAndFavicon = (title, favicon) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = process.env.PUBLIC_URL + favicon;
    document.title=title;
  }

  changeTitleAndFavicon("Free Palestine", "/initial.ico");


  setTimeout(function() {changeTitleAndFavicon("Free Pa̶l̶e̶s̶t̶i̶n̶e̶ the hostages", "/favicon.ico")} , 6000)

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <div class="text">Free <span class="strike">Palestine</span></div>
          <div class="appear_1 text">the</div> 
          <div class="appear_2 text">
            <a class="article_link" href="https://www.bbc.co.uk/news/world-middle-east-67053011" rel="noreferrer" target="_blank"> 
              Hostages
            </a> 
          </div>
          <div class="flag_appear">🇮🇱</div>
        </h1>
      </header>
    </div>
  );
}

export default App;
